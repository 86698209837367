import React, { useState, useEffect } from "react";
import cytoscape from "cytoscape";
import popper from "cytoscape-popper";
import { tippy } from "@tippy.js/react";
import { hideAll } from "tippy.js";
import "tippy.js/dist/tippy.css";

cytoscape.use(popper);

const Demo = (props) => {
  const [components, setComponents] = useState(null);
  const [parentNode, setParentNode] = useState(null);
  const [enlargedNode, setEnlargedNode] = useState(false);

  useEffect(() => {
    if (components && parentNode) {
      renderCytoscapeElement(components, parentNode);
    }
  }, [components, parentNode, props.refresh]);

  const fontSizeCalculate = (name) => {
    if (name.length > 25) {
      return enlargedNode === true ? "100px" : "45px";
    } else {
      return enlargedNode === true ? "110px" : "55px";
    }
  };

  const formatText = (text, sbu) => {
    text = text.trim();
    text = text.replaceAll(" ", "\n");
    text = text.replaceAll("-", "\n");

    if (sbu) {
      text = text + "\n\n" + sbu;
    }

    return text;
  };

  const edgeColor = (type) => {
    if (type && type.toLowerCase() === "mechanical") {
      return "#225eb3";
    } else {
      return "#b32246";
    }
  };

  const calcLabelSize = () => {
    return enlargedNode === true ? 500 : 50;
  };

  const renderCytoscapeElement = (components, parentNode) => {
    var myNodes = [];
    for (var i = 0; i < components.length; i++) {
      myNodes.push({
        data: {
          id: components[i].node_name,
          name: formatText(components[i].node_name, components[i].sbu),
          color: "#282c34",
          fontSize: fontSizeCalculate(components[i].node_name),
          comp_id: components[i].id,
          comp_name: components[i].node_name,
        },
      });
    }

    myNodes.push({
      data: {
        id: parentNode.parentNodeName,
        name: formatText(parentNode.parentNodeName, parentNode.sbu),
        color: "#282c34",
        fontSize: fontSizeCalculate(parentNode.parentNodeName),
        comp_id: parentNode.parentNodeId,
        parent: true,
        comp_name: parentNode.parentNodeName,
      },
    });

    var myEdges = [];

    for (i = 0; i < components.length; i++) {
      let index = myEdges.map((ele) => ele.data.id).indexOf(components[i].id);

      if (index >= 0) {
        myEdges[index].data.color = "#FFFF00";
      } else {
        myEdges.push({
          data: {
            id: components[i].id,
            source: parentNode.parentNodeName,
            target: components[i].node_name,
            color: edgeColor(components[i].interaction_type),
          },
        });
      }
    }

    let cy = cytoscape({
      container: document.getElementById("cy"),
      boxSelectionEnabled: true,
      autounselectify: true,
      wheelSensitivity: 0.1,
      style: [
        {
          selector: "node",
          style: {
            width: enlargedNode == true ? 1000 : 500,
            height: enlargedNode == true ? 1000 : 500,
            content: "data(name)",
            "background-color": "data(color)",
            border: "4px dotted orangered",
            "text-valign": "center",
            "text-halign": "center",
            "font-size": "data(fontSize)",
            "text-wrap": "wrap",
            color: "white",
            "text-outline-width": 2,
            "border-width": "10px",
            "border-color": "orangered",
            "text-outline-color": "#222",
          },
        },

        {
          selector: "edge",
          style: {
            width: 10,
            "target-arrow-shape": "triangle",
            "line-color": "data(color)",
            "target-arrow-color": "#9dbaea",
            "curve-style": "bezier",
          },
        },

        {
          selector: ":selected",
          style: {
            "background-color": "yellow",
            "line-color": "yellow",
            "target-arrow-color": "black",
            "source-arrow-color": "black",
          },
        },

        {
          selector: "edge:selected",
          style: {
            width: 20,
          },
        },
      ],

      elements: {
        nodes: myNodes,
        edges: myEdges,
      },

      layout: {
        name: "concentric",
        spacingFactor: enlargedNode === true ? 1 : 2,
      },
      animate: {},
    });
    cy.on("tap", "node", function (evt) {
      if (evt.target._private.data.comp_id != props.categoryId) {
        handleOpenManageLevel({
          id: evt.target._private.data.comp_id,
          name: evt.target._private.data.comp_name,
        });
      }
    });

    cy.on("mouseover", "node", function (event) {
      let node = event.target;
      let ref = node.popperRef();
      let dummyDomEle = document.createElement("div");

      let tip = tippy(dummyDomEle, {
        trigger: "manual",
        lazy: false,
        onCreate: (instance) => {
          instance.popperInstance.reference = ref;
          hideAll({ exclude: instance });
        },
        content: () => {
          let content = document.createElement("div");

          content.innerHTML = event.target._private.data.comp_name;
          // content.setAttribute("data-tippy-size", "jumbo");
          // content.className = "tooltip";

          return content;
        },
      });

      tip.show();
    });
  };

  useEffect(() => {
    if (props.categoryId) {
      var url = `${process.env.REACT_APP_DORMAN_API_URL}/manage_levels/${props.categoryId}`;

      if (process.browser) {
        localStorage.getItem("client") &&
          localStorage.getItem("access-token") &&
          fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "access-token": localStorage.getItem("access-token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              var comps = json.components;
              let distinct = [...new Set(comps.map((comp) => comp.id))];
              setEnlargedNode(distinct.length > 10);
              setComponents(comps);
              setParentNode({
                parentNodeId: json.id,
                parentNodeName: json.node_name,
                sbu: json.sbu,
              });
            });
      }
    }
  }, [props.categoryId]);

  let cyStyle = {};

  const handleOpenManageLevel = (obj) => {
    if (obj) {
      let normalFlow = props.historyCount == props.nextIndex;
      let index = 0;

      if (normalFlow) {
        if(props.history.length == 5) {
          props.history.shift();
        }

        index = props.history.length;
      } else {
        index = props.nextIndex + 1;
        props.history.splice(index);
      }

      props.setSelectedCategory({ id: obj.id });
      props.history[index] = obj;
      props.setHistory([...props.history]);
      props.setHistoryCount(index + 1);
      props.setNextIndex(index + 1);
      props.setNavHistory(props.history);
    }
  };

  return (
    <div>
      <div style={cyStyle} id="cy" className="home-cy" />
    </div>
  );
};

export default Demo;
