import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import paths from "../paths";
import styled from "styled-components";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import logo from "../../src/logo.jpg";
import { connect } from "react-redux";
import Logout from "../components/auth/logout";
import { Link, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#282c34",
    display: "inline-block",
  },
}));

const TabList = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Wrapper>
      {!props.home && (
        <AppBar
          title="My App"
          className={clsx(classes.appBar)}
          position="relative"
        >
          <div className="row">
            <div style={{ display: "inline-block" }} className="col-md-2">
              <div className="row">
                <div className="col-md-12">
                  <img
                    src={logo}
                    style={{ width: "80%", height: "70%", marginLeft: "20%" }}
                    className="mt-2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <center>
                    <span style={{ marginLeft: "20%" }}>
                      Managed levels map
                    </span>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-10 mt-2" style={{ paddingLeft: "0" }}>
              <Tabs style={{ display: "inline-flex" }}>
                <a href={paths.home}>
                  <Tab label="Home" />
                </a>
                {props.currentUser && props.currentUser.role == "Admin" && (
                  <>
                    <Link to={{ pathname: paths.new_category, reload: true }}>
                      <Tab label="New Manage Level" />
                    </Link>
                    <a href={paths.manage_levels}>
                      <Tab label="Manage Levels" />
                    </a>
                    <a href={paths.users}>
                      <Tab label="Users" />
                    </a>
                    <a href={paths.newAdmin}>
                      <Tab label="New Admin User" />
                    </a>
                  </>
                )}
                {props.currentUser && <Logout />}
              </Tabs>
            </div>
          </div>
        </AppBar>
      )}
      {props.home && (
        <div className="row">
          <div style={{ display: "inline-block" }} className="col-md-2 col-12">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: "-10px" }}>
                <img
                  src={logo}
                  style={{ width: "80%", height: "70%" }}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <center>
                  <span style={{ marginLeft: "-20%", fontSize: "14px" }}>
                    Managed levels map
                  </span>
                </center>
              </div>
            </div>
          </div>
          <div
            className="col-md-10 col-12 mt-2"
            style={{ paddingLeft: "0", marginLeft: "-4%" }}
          >
            <Tabs style={{ display: "inline-flex" }}>
              <a href={paths.home}>
                <Tab label="Home" />
              </a>
              {props.currentUser && props.currentUser.role == "Admin" && (
                <>
                  <Link to={{ pathname: paths.new_category, reload: true }}>
                    <Tab label="New Manage Level" />
                  </Link>

                  <a href={paths.manage_levels}>
                    <Tab label="Manage Levels" />
                  </a>
                  <a href={paths.users}>
                    <Tab label="Users" />
                  </a>
                  <a href={paths.newAdmin}>
                    <Tab label="New Admin User" />
                  </a>
                </>
              )}
              {props.currentUser && <Logout />}
            </Tabs>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  a {
    color: white;
  }
`;

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default withRouter(connect(mapStateToProps, null)(TabList));
