import React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout = (props) => {
  return (
    <>
      {props.children}
    </>
  );
};

export default Layout;
