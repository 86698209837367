import React from "react";
import "./App.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import Main from "../src/components/main";
import { Provider } from "react-redux";
import store from "../src/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "chosen-js";
import "chosen-js/chosen.css";
import "font-awesome/css/font-awesome.min.css";

const App = () => {
  return (
    <Provider store={store()}>
      <Main />
      <ToastContainer />
    </Provider>
  );
};

export default App;
