export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER = "SET_USER";
export const SET_USER_LIST = "SET_USER_LIST";
export const REPLACE_USER_FROM_LIST = "REPLACE_USER_FROM_LIST";
export const DELETE_USER_FROM_LIST = "DELETE_USER_FROM_LIST";
export const SET_MANAGE_LEVELS_UPLOADING = "SET_MANAGE_LEVELS_UPLOADING";

export function setCategory(category) {
  return {
    type: SET_CATEGORY,
    category: category,
  };
}

export function setCategoryList(list) {
  return {
    type: SET_CATEGORY_LIST,
    list: list,
  };
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user: user,
  };
}

export function signOutUser() {
  return {
    type: LOGOUT_USER,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user: user,
  };
}

export function setUserList(list) {
  return {
    type: SET_USER_LIST,
    list: list,
  };
}

export function replaceUserFromList(user) {
  return {
    type: REPLACE_USER_FROM_LIST,
    user: user,
  };
}

export function deleteUserFromList(user) {
  return {
    type: DELETE_USER_FROM_LIST,
    user: user,
  };
}

export function setManageLevelsUploading(uploading) {
  return {
    type: SET_MANAGE_LEVELS_UPLOADING,
    uploading: uploading,
  };
}
