import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import Tabs from "../tabs";
import paths from "../../paths";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { signOutUser } from "../../actions";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const NewCategory = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
  }));

  const classes = useStyles();

  const emptyComponent = {
    conn_type: "electrical",
    id: null,
    errors: {},
    component_id: null,
    _destroy: false,
  };

  const [manageLevel, setManageLevel] = useState({
    manage_level: {
      id: null,
      component_id: null,
      connections_attributes: [Object.assign({}, emptyComponent)],
    },
  });

  const [categoryList, setCategoryList] = useState(null);

  useEffect(() => {
    let url = `${process.env.REACT_APP_DORMAN_API_URL}/home`;

    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("access-token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
          .then((response) => {
            if (response.status == 401) {
              props.signOutUser();
              props.history.push(paths.signIn);
            }
          })
          .catch((error) => {
            alert(error);
            // toast.error(error);
          });
    }
  }, []);

  useEffect(() => {
    if (props.location.state && props.location.state.manageLevelId) {
      var url = `${process.env.REACT_APP_DORMAN_API_URL}/manage_levels/${props.location.state.manageLevelId}`;

      if (process.browser) {
        localStorage.getItem("client") &&
          localStorage.getItem("access-token") &&
          fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "access-token": localStorage.getItem("access-token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              setManageLevel({
                manage_level: {
                  id: json.id,
                  code: json.code,
                  name: json.name,
                  connections_attributes: json.connections,
                },
              });
            });
      }
    }

    fetchCategories();
  }, []);

  const fetchCategories = (searchTerm) => {
    let url = `${process.env.REACT_APP_DORMAN_API_URL}/manage_levels?`;

    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("access-token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((responseJson) => {
            setCategoryList(responseJson.records);
          })
          .catch((error) => {
            toast.error(error);
          });
    }
  };

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push(paths.signIn);
    }
  });

  useEffect(() => {
    console.log(manageLevel);
  }, [manageLevel]);

  const handleCancel = () => {
    props.history.push("/");
  };

  const handleFormSubmit = () => {
    var url = `${process.env.REACT_APP_DORMAN_API_URL}/manage_levels/`;
    if (manageLevel.manage_level.id) {
      url = url + `${manageLevel.manage_level.id}`;
    }

    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(url, {
          method: manageLevel.manage_level.id ? "PUT" : "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("access-token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
          body: JSON.stringify(manageLevel),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            if (json.errors) {
              throw new Error(json.errors);
            } else {
              props.history.push(paths.manage_levels);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            setManageLevel({ manage_level: manageLevel.manage_level });
          });
    }
  };

  const handleAddComponent = (e) => {
    e.preventDefault();

    manageLevel &&
      manageLevel.manage_level.connections_attributes.push(
        Object.assign({}, emptyComponent)
      );
    setManageLevel({ manage_level: manageLevel.manage_level });
  };

  const renderComponentsForm = () => {
    let counter = 0;
    return (
      manageLevel &&
      manageLevel.manage_level.connections_attributes.map(
        (component, index) => {
          if (component._destroy === false) {
            let componentDOM = (
              <div className="col-md-12" key={index}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="clearfix" style={{ marginBottom: 5 }}>
                        <label>Component {counter + 1}</label>
                        <button
                          className="btn btn-danger"
                          style={{ padding: "5px 10px", float: "right" }}
                          onClick={(e) => handleRemoveComponent(component)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <label>Name</label>
                      <select
                        name="component_id"
                        className="form-control"
                        onChange={(event) =>
                          onComponentTitleChange(event, component)
                        }
                        value={
                          component.component_id ? component.component_id : ""
                        }
                      >
                        <option disabled={true} value="">
                          Select Component
                        </option>
                        {categoryList &&
                          categoryList.map((item) => {
                            return (
                              <option value={item.id}>
                                {item.code +
                                  "-" +
                                  item.name.replaceAll(item.code + "-", "")}
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <label>Interaction Type</label>
                      <select
                        onChange={(event) =>
                          onComponentTitleChange(event, component)
                        }
                        type="text"
                        value={component.conn_type}
                        className="form-control"
                        name="conn_type"
                      >
                        <option value="electrical">Electrical</option>
                        <option value="mechanical">Mechanical</option>
                      </select>
                      {/* {renderComponentInlineError(component)} */}
                    </div>
                  </div>
                </div>
              </div>
            );
            counter++;

            return componentDOM;
          } else {
            return null;
          }
        }
      )
    );
  };

  const onComponentTitleChange = (event, component) => {
    component[event.target.name] = event.target.value;
    setManageLevel({ manage_level: manageLevel.manage_level });
  };

  const handleRemoveComponent = (component) => {
    component._destroy = true;
    setManageLevel({ manage_level: manageLevel.manage_level });
  };

  const handleProjectNameChange = (e) => {
    let manage_level = manageLevel && manageLevel.manage_level;
    manage_level[e.target.name] = e.target.value;
    setManageLevel({ manage_level: manageLevel.manage_level });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <Tabs />
      </ElevationScroll>
      <Container className="mt-3">
        <div className="ProjectForm">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  onChange={(e) => handleProjectNameChange(e)}
                  value={manageLevel && manageLevel.manage_level.name}
                  className="form-control"
                  name="name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Code</label>
                <input
                  type="text"
                  onChange={(e) => handleProjectNameChange(e)}
                  value={manageLevel && manageLevel.manage_level.code}
                  className="form-control"
                  name="code"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="components-fieldset">
            <h3>Components</h3>
            <div className="row">{renderComponentsForm()}</div>
            <button
              className="btn btn-success"
              onClick={(e) => handleAddComponent(e)}
            >
              + Add Component
            </button>
          </div>
          <br />
          <button
            onClick={(e) => handleFormSubmit()}
            className="btn btn-primary"
          >
            Save
          </button>
          &nbsp;
          <button onClick={(e) => handleCancel()} className="btn btn-default">
            Cancel
          </button>{" "}
        </div>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    categories: state.manageLevel.categoryList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewCategory)
);
