import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import Tabs from "../tabs";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ActionCable from "actioncable";
import Papa from "papaparse";
import Tooltip from "@material-ui/core/Tooltip";
import { setManageLevelsUploading } from "../../actions";
import { signOutUser } from "../../actions";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const ManageLevelsList = (props) => {
  const [manageLevels, setManageLevels] = useState(null);
  const [pages, setPages] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [manageLevelsCsv, setManageLevelsCsv] = useState(null);
  const [uploading, setUploading] = useState(false);

  const cable = ActionCable.createConsumer(
    `${process.env.REACT_APP_CABLE_URL}/cable`
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [activePage, searchTerm]);

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push(paths.signIn);
    }
  });

  const fetchCategories = () => {
    let url = `${
      process.env.REACT_APP_DORMAN_API_URL
    }/manage_levels?page=${activePage}&per_page=${true}`;

    if (searchTerm) {
      url = url + `&name=${searchTerm}`;
    }

    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("access-token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else if (response.status == 401) {
              props.signOutUser();
              props.history.push(paths.root);
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((responseJson) => {
            if (responseJson != undefined) {
              setManageLevels(responseJson.records);
              setPages(responseJson.total_pages);
            }
          })
          .catch((error) => {
            alert(error);
            // toast.error(error);
          });
    }
  };

  const handleClick = (e, id) => {
    e.preventDefault();

    props.history.push(paths.new_category, { manageLevelId: id });
    return false;
  };

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleCsvChange = (event) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const results = Papa.parse(bstr, {
        header: false,
      });

      if (checkFileValidity(results.data[0])) {
        setManageLevelsCsv(file);
      } else {
        toast.error("Invalid file headers");
        setUploading(true);

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    };
    reader.readAsBinaryString(file);
  };

  const checkFileValidity = (headers) => {
    let result = true;

    const validHeaders = [
      "Managed Level Code",
      "Managed Level Description",
      "SBU",
      "Interface Type",
    ];

    for (var index in headers) {
      let header = headers[index];
      let split = header.split(" ");

      if (
        !validHeaders.includes(header) &&
        split[0]?.toLowerCase() != "connection" &&
        isNaN(split[1])
      ) {
        result = false;
        break;
      }
    }

    return result;
  };

  const uploadCSV = () => {
    if (!manageLevelsCsv) {
      return;
    }

    props.setUploading(true);

    let formData = new FormData();
    formData.append("file", manageLevelsCsv);

    var url = `${process.env.REACT_APP_DORMAN_API_URL}/uploads/import`;

    fetch(url, {
      method: "POST",
      headers: {
        "access-token": localStorage.getItem("access-token"),
        client: localStorage.getItem("client"),
        uid: localStorage.getItem("uid"),
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.errors) {
          throw new Error(json.errors);
        } else {
          toast.success(json.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <Tabs />
      </ElevationScroll>
      <Container className="mt-3">
        <Wrapper className="">
          <div className="row">
            <div className="col-md-6">
              <input
                type="file"
                id="import_file"
                name="import_file"
                accept=".csv"
                className="form-control"
                onChange={handleCsvChange}
              />
            </div>
            <div className="col-md-2">
              <button
                className="btn btn-dark"
                onClick={uploadCSV}
                // disabled={
                //   props.uploading && props.uploading == true
                //     ? props.uploading
                //     : uploading
                // }
              >
                Upload
              </button>
            </div>

            <div className="col-md-3 text-right">
              <a
                href={`${
                  process.env.REACT_APP_DORMAN_API_URL
                }/uploads/export?sample_headers=${true}`}
                className="btn btn-dark"
                style={{ marginRight: "-20%" }}
              >
                Download Sample Headers
              </a>
            </div>

            <div className="col-md-1 text-right">
              <Tooltip title="Export Data">
                <a
                  href={`${process.env.REACT_APP_DORMAN_API_URL}/uploads/export`}
                  className="btn btn-dark"
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div className="col-4">
                    <h2>Manage Levels</h2>
                  </div>
                  <div class="col" />
                  <div className="col-4">
                    <input
                      className="form-control"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Code</th>
                    <th>SBU</th>
                  </tr>
                </thead>
                <tbody>
                  {manageLevels &&
                    manageLevels.map((category) => {
                      return (
                        <tr>
                          <td>{category.id}</td>
                          <td>
                            <a
                              href="#"
                              onClick={(e) => handleClick(e, category.id)}
                            >
                              {category.node_name}
                            </a>
                          </td>

                          <td>
                            <a href="#">{category.code}</a>
                          </td>
                          <td>{category.sbu}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {pages && (
            <PaginationWrapper>
              <Pagination
                className="pagination"
                count={pages}
                variant="outlined"
                shape="rounded"
                color="primary"
                showLastButton={true}
                showFirstButton={true}
                onChange={handlePageChange}
              />
            </PaginationWrapper>
          )}
        </Wrapper>
      </Container>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .table-responsive {
    margin: 30px 0;
  }
  .table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover,
  .table-title .btn:focus {
    color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
  table.table tr th,
  table.table tr td {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
  }
  table.table tr th:first-child {
    width: 60px;
  }
  table.table tr th:last-child {
    width: 100px;
  }
  table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
  table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
  }
  table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
  }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196f3;
  }
  table.table td a.settings {
    color: #2196f3;
  }
  table.table td a.delete {
    color: #f44336;
  }
  table.table td i {
    font-size: 19px;
  }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
  .text-success {
    color: #10c469;
  }
  .text-info {
    color: #62c9e8;
  }
  .text-warning {
    color: #ffc107;
  }
  .text-danger {
    color: #ff5b5b;
  }
  .pagination {
    float: right;
    margin: 0 0 5px;
  }
  .pagination li a {
    border: none;
    font-size: 13px;
    min-width: 30px;
    min-height: 30px;
    color: #999;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 2px !important;
    text-align: center;
    padding: 0 6px;
  }
  .pagination li a:hover {
    color: #666;
  }
  .pagination li.active a,
  .pagination li.active a.page-link {
    background: #03a9f4;
  }
  .pagination li.active a:hover {
    background: #0397d6;
  }
  .pagination li.disabled i {
    color: #ccc;
  }
  .pagination li i {
    font-size: 16px;
    padding-top: 6px;
  }
  .hint-text {
    float: left;
    margin-top: 10px;
    font-size: 13px;
  }
`;

const PaginationWrapper = styled.div`
  button {
    background: black;
    color: white;
  }
`;

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    uploading: state.manageLevel.uploading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUploading: (uploading) => dispatch(setManageLevelsUploading(uploading)),
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageLevelsList)
);
