import React, { useEffect } from "react";
import Layout from "./layout";
import Home from "./home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import paths from "../paths";
import NewCategory from "../components/manage_levels/newCategory";
import ManageLevelsList from "../components/manage_levels/manageLevelsList";
import Signup from "../components/auth/signup";
import Login from "../components/auth/login";
import UserList from "../components/users/userList";
import NewAdmin from "../components/users/newAdmin";
import ActionCable from "actioncable";
import { setManageLevelsUploading } from "../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
window.$ = window.jQuery = require("jquery");

const Main = (props) => {
  const cable = ActionCable.createConsumer(
    `${process.env.REACT_APP_CABLE_URL}/cable`
  );

  useEffect(() => {
    // createSubscription();
  }, []);

  const createSubscription = () => {
    cable.subscriptions.create(
      { channel: "NotificationsChannel" },
      { received: (message) => handleReceivedMessage(message) }
    );
  };

  const handleReceivedMessage = (message) => {
    props.setUploading(false);
    toast.success("Uploaded data successfully");

    setTimeout(() => {
      if (
        typeof window !== `undefined` &&
        window.location.pathname.split("/")[1] == "manage-levels"
      ) {
        window.location.reload();
      }
    }, 3000);
  };

  return (
    <Router>
      <Layout>
        <Switch>
          <Route path={paths.home}>
            <Home />
          </Route>
          <Route path={paths.signUp}>
            <Signup />
          </Route>
          <Route path={paths.signIn}>
            <Login />
          </Route>
          <Route path={paths.new_category}>
            <NewCategory />
          </Route>
          <Route path={paths.manage_levels}>
            <ManageLevelsList />
          </Route>
          <Route path={paths.users}>
            <UserList />
          </Route>
          <Route path={paths.newAdmin}>
            <NewAdmin />
          </Route>
          <Route path={paths.root}>
            <Home />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUploading: (uploading) => dispatch(setManageLevelsUploading(uploading)),
  };
};

export default connect(null, mapDispatchToProps)(Main);
