import React from "react";
import paths from "../../paths";
import { signOutUser } from "../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Tab } from "@material-ui/core";

const Logout = (props) => {
  const submitForm = (e) => {
    e.preventDefault();
    toast.success("Signed out successfully");

    props.signOutUser();
    props.history.push(paths.root);
  };

  return (
    <a href="#" onClick={submitForm}>
      <Tab label="Logout" />
    </a>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Logout));
