import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import paths from "../../paths";
import { connect } from "react-redux";
import { setCurrentUser } from "../../actions";
import { toast } from "react-toastify";

const Signup = (props) => {
  const [state, setState] = useState({ type: "Employee" });
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (props.currentUser) {
      props.history.push(paths.home);
    }
  });

  const handleChange = (e) => {
    setErrors(null);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = () => {
    fetch(`${process.env.REACT_APP_DORMAN_API_URL}/auth/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: state }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((responseJson) => {
            for (const header of response.headers) {
              if (["access-token", "uid", "client"].includes(header[0])) {
                localStorage.setItem(header[0], header[1]);
              }
            }
            toast.success("Account created successfully");
            props.setCurrentUser(responseJson.data);
            props.history.push("/home");
          });
        } else {
          return response.json().then((responseJson) => {
            throw new Error(responseJson.errors.full_messages.join(", "));
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};
    if (!state.first_name || state.first_name.trim() == "")
      errors.first_name = "This field is required.";
    if (!state.last_name || state.last_name.trim() == "")
      errors.last_name = "This field is required.";
    if (!state.email || state.email.trim() == "")
      errors.email = "This field is required.";
    if (
      state.password &&
      (!state.password_confirmation || state.password_confirmation.trim() == "")
    ) {
      errors.password_confirmation = "This field is required.";
    }
    if (
      state.password_confirmation &&
      (!state.password || state.password.trim() == "")
    ) {
      errors.password = "This field is required.";
    }
    if (
      state.password &&
      state.password.length >= 0 &&
      state.password.trim() == ""
    ) {
      delete state["password"];
    }
    if (Object.keys(errors) == 0) {
      errors = null;
    }
    setErrors(errors);
    if (!errors) {
      createUser();
    }
  };

  return (
    <Wrapper className="container">
      <div class="signup-form">
        <h2>Sign Up</h2>
        <hr />
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            name="first_name"
            placeholder="First Name"
            required="required"
            onChange={handleChange}
          />
          <span style={{ color: "red" }}>{errors && errors.first_name}</span>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            name="last_name"
            placeholder="Last Name"
            required="required"
            onChange={handleChange}
          />
          <span style={{ color: "red" }}>{errors && errors.last_name}</span>
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            name="email"
            placeholder="Email Address"
            required="required"
            onChange={handleChange}
          />
          <span style={{ color: "red" }}>{errors && errors.email}</span>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            name="password"
            placeholder="Password"
            required="required"
            onChange={handleChange}
          />
          <span style={{ color: "red" }}>{errors && errors.password}</span>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            name="password_confirmation"
            placeholder="Confirm Password"
            required="required"
            onChange={handleChange}
          />
          <span style={{ color: "red" }}>
            {errors && errors.password_confirmation}
          </span>
        </div>
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-dark btn-block btn-lg"
            onClick={handleSubmit}
          >
            Sign Up
          </button>
        </div>
        <div class="text-center">
          Already have an account? <a href={paths.signIn}>Login here</a>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;

  .form-control {
    min-height: 41px;
    box-shadow: none;
    border-color: #e1e4e5;
    font-size: 14px;
  }
  .form-control,
  .btn {
    border-radius: 3px;
  }
  .signup-form {
    width: 400px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .signup-form form {
    color: #9ba5a8;
    border-radius: 3px;
    margin-bottom: 15px;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
  }
  .signup-form h2 {
    color: #333;
    font-weight: bold;
    margin-top: 0;
  }
  .signup-form hr {
    margin: 0 -30px 20px;
  }
  .signup-form .form-group {
    margin-bottom: 20px;
  }
  .signup-form label {
    font-weight: normal;
    font-size: 13px;
  }
  .signup-form .btn,
  .signup-form .btn:active {
    font-size: 16px;
    font-weight: bold;
    border: none;
    min-width: 140px;
  }
  .signup-form a {
    text-decoration: underline;
  }
  .signup-form a:hover {
    text-decoration: none;
  }
  .signup-form form a {
    color: #5fcaba;
    text-decoration: none;
  }
  .signup-form form a:hover {
    text-decoration: underline;
  }
`;

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
