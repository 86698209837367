import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import paths from "../../paths";
import { connect } from "react-redux";
import { setCurrentUser } from "../../actions";
import { toast } from "react-toastify";

const Login = (props) => {
  const [state, setState] = useState({});

  useEffect(() => {
    if (props.currentUser) {
      props.history.push(paths.home);
    }
  });

  const handleChange = (e) => {
    state[e.target.name] = e.target.value;
    setState(state);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_DORMAN_API_URL}/auth/sign_in`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state),
    })
      .then((response) => {
        return response.json().then((responseJson) => {
          for (const header of response.headers) {
            if (["access-token", "uid", "client"].includes(header[0])) {
              localStorage.setItem(header[0], header[1]);
            }
          }
          if (!responseJson.errors) {
            toast.success("Signed in successfully");
            props.setCurrentUser(responseJson);
            props.history.push("/home");
          } else {
            toast.error("Invalid credentials.");
          }
        });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <Wrapper className="container">
      <div class="signup-form">
        <h2>Sign In</h2>
        <hr />
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            name="email"
            placeholder="Email Address"
            required="required"
            onChange={handleChange}
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            name="password"
            placeholder="Password"
            required="required"
            onChange={handleChange}
          />
        </div>
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-dark btn-block btn-lg"
            onClick={handleLoginClick}
          >
            Login
          </button>
        </div>
        <div class="text-center">
          New to the system? <a href={paths.signUp}>Register here</a>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;

  .form-control {
    min-height: 41px;
    box-shadow: none;
    border-color: #e1e4e5;
    font-size: 14px;
  }
  .form-control,
  .btn {
    border-radius: 3px;
  }
  .signup-form {
    width: 400px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .signup-form form {
    color: #9ba5a8;
    border-radius: 3px;
    margin-bottom: 15px;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
  }
  .signup-form h2 {
    color: #333;
    font-weight: bold;
    margin-top: 0;
  }
  .signup-form hr {
    margin: 0 -30px 20px;
  }
  .signup-form .form-group {
    margin-bottom: 20px;
  }
  .signup-form label {
    font-weight: normal;
    font-size: 13px;
  }
  .signup-form .btn,
  .signup-form .btn:active {
    font-size: 16px;
    font-weight: bold;
    border: none;
    min-width: 140px;
  }
  .signup-form a {
    text-decoration: underline;
  }
  .signup-form a:hover {
    text-decoration: none;
  }
  .signup-form form a {
    color: #5fcaba;
    text-decoration: none;
  }
  .signup-form form a:hover {
    text-decoration: underline;
  }
`;

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
