import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  setUserList,
  replaceUserFromList,
  deleteUserFromList,
  signOutUser,
} from "../../actions";
// import EditUserModal from "./editUserModal";
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import PropTypes from "prop-types";
import Tabs from "../tabs";
import Toolbar from "@material-ui/core/Toolbar";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
const UserList = (props) => {
  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState(null);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push(paths.signIn);
    }
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [activePage]);

  const updateList = (user) => {
    let index = users.map((ele) => ele.id).indexOf(user.id);
    users[index] = user;
    setUsers([...users]);
  };

  const deleteUserFromList = (user) => {
    let index = users.map((ele) => ele.id).indexOf(user.id);
    users.splice(index, 1);
    setUsers([...users]);
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUserItem(id);
    }
  };

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const fetchUsers = () => {
    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(
          `${process.env.REACT_APP_DORMAN_API_URL}/admin/users?page=${activePage}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "access-token": localStorage.getItem("access-token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else if (response.status == 401) {
              props.signOutUser();
              props.history.push(paths.root);
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((responseJson) => {
            if (responseJson != undefined) {
              setUsers(responseJson.records);
              setPages(responseJson.total_pages);
            }
          })
          .catch((error) => {
            alert(error);
            // toast.error(error);
          });
    }
  };

  const deleteUserItem = (id) => {
    fetch(`${process.env.REACT_APP_DORMAN_API_URL}/admin/users/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": localStorage.getItem("access-token"),
        client: localStorage.getItem("client"),
        uid: localStorage.getItem("uid"),
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((responseJson) => {
            toast.success("User deleted successfully");
            deleteUserFromList(responseJson);
          });
        } else {
          return response.json().then((responseJson) => {
            throw new Error(responseJson.error);
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const changeRole = (user) => {
    fetch(`${process.env.REACT_APP_DORMAN_API_URL}/admin/users/${user.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": localStorage.getItem("access-token"),
        client: localStorage.getItem("client"),
        uid: localStorage.getItem("uid"),
      },
      body: JSON.stringify({
        type: user.role === "Admin" ? "Employee" : "Admin",
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((responseJson) => {
            toast.success("Permission changed successfully");
            updateList(responseJson);
          });
        } else {
          return response.json().then((responseJson) => {
            throw new Error(responseJson.error);
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <Tabs />
      </ElevationScroll>
      <Container className="mt-3">
        <Wrapper className="">
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div className="col-4">
                    <h2>Users Management</h2>
                  </div>
                  <div class="col" />
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    {props.currentUser?.role == "Admin" && (
                      <>
                        <th>Permissions</th>
                        <th>Actions</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((user) => {
                      if (user.id != props.currentUser.id) {
                        return (
                          <tr>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            {props.currentUser?.role == "Admin" && (
                              <>
                                <td>
                                  {" "}
                                  <div id="div-yesnotoggle">
                                    <div id="div-notext">Employee</div>
                                    <div id="div-switch">
                                      <Tooltip
                                        title={
                                          user.role === "Admin"
                                            ? "Mark as Employee"
                                            : "Mark as Admin"
                                        }
                                      >
                                        <Switch
                                          checked={
                                            user.role === "Admin" ? true : false
                                          }
                                          name="checkedA"
                                          id={`switch-${user.id}`}
                                          inputProps={{
                                            "aria-label": "secondary checkbox",
                                          }}
                                          onClick={() => changeRole(user)}
                                          color="default"
                                        />
                                      </Tooltip>
                                    </div>
                                    <div id="div-yestext">Admin</div>
                                  </div>
                                </td>
                                <td>
                                  <center>
                                    <a
                                      href="#"
                                      className="delete"
                                      title="Delete"
                                      data-toggle="tooltip"
                                      onClick={(e) => handleDelete(e, user.id)}
                                    >
                                      <i className="fa fa-trash" />
                                    </a>
                                  </center>
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {pages && (
            <PaginationWrapper>
              <Pagination
                className="pagination"
                count={pages}
                variant="outlined"
                shape="rounded"
                color="primary"
                showLastButton={true}
                showFirstButton={true}
                onChange={handlePageChange}
              />
            </PaginationWrapper>
          )}
        </Wrapper>
      </Container>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .table-responsive {
    margin: 30px 0;
  }
  .table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover,
  .table-title .btn:focus {
    color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
  table.table tr th,
  table.table tr td {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
  }
  table.table tr th:first-child {
    width: 60px;
  }
  table.table tr th:last-child {
    width: 100px;
  }
  table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
  table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
  }
  table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
  }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196f3;
  }
  table.table td a.settings {
    color: #2196f3;
  }
  table.table td a.delete {
    color: #f44336;
  }
  table.table td i {
    font-size: 19px;
  }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
  .text-success {
    color: #10c469;
  }
  .text-info {
    color: #62c9e8;
  }
  .text-warning {
    color: #ffc107;
  }
  .text-danger {
    color: #ff5b5b;
  }
  .pagination {
    float: right;
    margin: 0 0 5px;
  }
  .pagination li a {
    border: none;
    font-size: 13px;
    min-width: 30px;
    min-height: 30px;
    color: #999;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 2px !important;
    text-align: center;
    padding: 0 6px;
  }
  .pagination li a:hover {
    color: #666;
  }
  .pagination li.active a,
  .pagination li.active a.page-link {
    background: #03a9f4;
  }
  .pagination li.active a:hover {
    background: #0397d6;
  }
  .pagination li.disabled i {
    color: #ccc;
  }
  .pagination li i {
    font-size: 16px;
    padding-top: 6px;
  }
  .hint-text {
    float: left;
    margin-top: 10px;
    font-size: 13px;
  }
`;

const PaginationWrapper = styled.div`
  button {
    background: black;
    color: white;
  }
`;

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
);
