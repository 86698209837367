export default {
  home: "/home",
  root: "/",
  new_category: "/new-manage-level",
  manage_levels: "/manage-levels",
  signUp: "/sign-up",
  signIn: "/sign-in",
  users: "/users",
  newAdmin: "/new-admin",
};
