import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import Demo from "./Demo";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { setCategoryList, signOutUser } from "../actions";
import Tabs from "../components/tabs";
import paths from "../paths";
import { withRouter } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { toast } from "react-toastify";

const drawerWidth = 310;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#282c34",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#282c34",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
    background: "#282c34",
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    background: "#282c34",
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#282c34",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    background: "#282c34",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    background: "#282c34",
  },
  searchField: {
    color: "white",
  },
  listItem: {
    color: "white",
    "&:hover": {
      background: "#f78707",
    },
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [visitHistory, setVisitHistory] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [searchHistory, setSearchHistory] = useState([]);
  const [searchHistoryCount, setSearchHistoryCount] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [navHistory, setNavHistory] = useState([]);

  useEffect(() => {
    let url = `${process.env.REACT_APP_DORMAN_API_URL}/home`;

    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("access-token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
          .then((response) => {
            if (response.status == 401) {
              props.signOutUser();
              props.history.push(paths.signIn);
            }
          })
          .catch((error) => {
            alert(error);
            // toast.error(error);
          });
    }
  }, []);

  useEffect(() => {
    if (props.categoryList) {
      setCategories(props.categoryList);
    }
  }, [props.categoryList]);

  useEffect(() => {
    if (localStorage.getItem("searchHistory")) {
      let result = JSON.parse(localStorage.getItem("searchHistory"));
      setSearchHistory(result.trace ? result.trace : []);
      setSearchHistoryCount(result.index ? result.index : 0);
      setCategories(searchHistory);
    }
  }, []);

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push(paths.signIn);
    }
  });

  useEffect(() => {
    setNextIndex(historyCount);
  }, [historyCount]);

  useEffect(() => {
    localStorage.setItem(
      "searchHistory",
      JSON.stringify({ trace: searchHistory, index: searchHistoryCount })
    );
  }, [searchHistory, searchHistoryCount]);

  useEffect(() => {
    if (searchTerm) {
      setVisitHistory([]);
      setHistoryCount(0);
      fetchCategories(searchTerm);
    } else {
      setCategories(searchHistory);
      setSelectedCategory(null);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!searchTerm) {
      setCategories(searchHistory);
    }
  }, [searchHistory]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fetchCategories = (searchTerm) => {
    let url = `${process.env.REACT_APP_DORMAN_API_URL}/manage_levels?`;
    if (searchTerm) {
      url = url + `name=${searchTerm}`;
    }

    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("access-token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else if (response.status == 401) {
              props.signOutUser();
              props.history.push(paths.root);
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((responseJson) => {
            if (responseJson != undefined) {
              props.setCategoryList(responseJson.records);
            }
          })
          .catch((error) => {
            alert(error);
            // toast.error(error);
          });
    }
  };

  const handleCategoryClick = (item) => {
    if (!item && !item.id) {
      return;
    }

    setSelectedCategory(item);
    // updateNavHistory(item);

    if (!isItemAlreadySearched(item, searchHistory)) {
      let index = searchHistoryCount == 5 ? 0 : searchHistoryCount;
      searchHistory[index] = item;
      setSearchHistory([...searchHistory]);
      setSearchHistoryCount(index + 1);
    }

    let normalFlow = historyCount == nextIndex;
    let index = 0;

    if (normalFlow) {
      if (!isItemVisited(item, visitHistory.length, visitHistory.length > 0)) {
        if (visitHistory.length == 5) {
          visitHistory.shift();
        }

        index = visitHistory.length;
        visitHistory[index] = { id: item.id, name: item.node_name };
        setVisitHistory([...visitHistory]);
        setHistoryCount(index + 1);
        setNextIndex(index + 1);
      }
    } else {
      index = nextIndex + 1;
      if (!isItemVisited(item, index, visitHistory.length > 0)) {
        visitHistory.splice(index);
        visitHistory[index] = { id: item.id, name: item.node_name };
        setVisitHistory([...visitHistory]);
        setHistoryCount(index + 1);
        setNextIndex(index + 1);
      } else {
        visitHistory.splice(index);
        setVisitHistory([...visitHistory]);
        setHistoryCount(index);
        setNextIndex(index);
      }
    }

    setNavHistory(visitHistory);
  };

  const isItemVisited = (item, searchIndex, valid) => {
    if (valid) {
      return visitHistory[searchIndex - 1].id == item.id;
    } else {
      return false;
    }
  };

  const updateNavHistory = (item) => {
    let length = navHistory.length;

    if (length > 0 && navHistory[length - 1].id == item.id) {
      return;
    }

    if (length < 5) {
      navHistory.push(item);
    } else {
      navHistory.shift();
      navHistory.push(item);
    }

    setNavHistory([...navHistory]);
  };

  const isItemAlreadySearched = (item, arr) => {
    let index = arr.indexOf(item);
    return index >= 0;
  };

  const isVisitHistoryPresent = () => {
    if (visitHistory && visitHistory.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleNextClick = (event) => {
    if (
      visitHistory &&
      visitHistory.length > 0 &&
      visitHistory[nextIndex + 1]
    ) {
      setSelectedCategory({
        ...selectedCategory,
        id: visitHistory[nextIndex + 1].id,
      });
      setNextIndex(nextIndex + 1);
      navHistory &&
        navHistory.length < 5 &&
        setNavHistory([...navHistory, visitHistory[nextIndex + 1]]);
    } else {
      event.preventDefault();
    }
  };

  const handleBackClick = (event) => {
    let index = 0;

    if (visitHistory && visitHistory.length > 0) {
      index = nextIndex - 1;
      if (
        visitHistory[index] &&
        visitHistory[index].id == selectedCategory.id
      ) {
        index = index - 1;
      }
    }

    if (visitHistory && visitHistory.length > 0 && visitHistory[index]) {
      setSelectedCategory({
        ...selectedCategory,
        id: visitHistory[index].id,
      });
      setNextIndex(index);
      navHistory &&
        navHistory.length > 1 &&
        setNavHistory(navHistory.slice(0, navHistory.length - 1));
    } else {
      event.preventDefault();
    }
  };

  const clearSearchHistory = () => {
    setSearchHistory([]);
    setSearchHistoryCount(0);
    setSearchTerm("");
  };

  const handleNavigationClick = (event, item) => {
    event.preventDefault();

    setSelectedCategory({
      ...selectedCategory,
      id: item.id,
    });

    updateNavHistory(item);
  };

  return (
    <div className={classes.root}>
      <div className="row">
        <CssBaseline />
        <AppBar position="relative" className={clsx(classes.appBar)}>
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              {" "}
              <Tabs home={true} />
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <i
              className="fa fa-2x fa-search mr-2"
              style={{ color: "orange" }}
            />
            <TextField
              id="time"
              type="text"
              placeholder="Enter Managed Level or Description"
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                className: clsx(classes.searchField),
              }}
              style={{ width: "100%" }}
              value={searchTerm}
            />
            <button
              className={`btn btn-sm ml-2 ${
                searchHistory && searchHistory.length == 0 ? "d-none" : ""
              }`}
              style={{ background: "#e76f51", color: "white" }}
              onClick={clearSearchHistory}
            >
              Clear
            </button>
          </div>
          <Divider />
          <List>
            {categories &&
              categories.map((item, index) => {
                return (
                  <ListItem
                    button
                    key={index}
                    className={clsx(classes.listItem)}
                  >
                    <ListItemText
                      primary={item.node_name}
                      onClick={() => handleCategoryClick(item)}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Drawer>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-9">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {navHistory?.map((item) => {
                    return (
                      <span
                        style={{ color: "orange" }}
                        // href="/"
                        // onClick={(event) => handleNavigationClick(event, item)}
                      >
                        {item.node_name || item.name}
                      </span>
                    );
                  })}
                  {/* <Typography color="textPrimary">Breadcrumb</Typography> */}
                </Breadcrumbs>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1%" }}>
              <div class="col-md-6">
                <button
                  className="btn"
                  disabled={isVisitHistoryPresent()}
                  onClick={handleBackClick}
                  style={{ background: "#e76f51", color: "white" }}
                >
                  Back
                </button>
                <button
                  className="btn btn ml-2"
                  disabled={isVisitHistoryPresent()}
                  onClick={handleNextClick}
                  style={{ background: "#e76f51", color: "white" }}
                >
                  Next
                </button>
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => setRefresh(!refresh)}
                >
                  Reset
                </button>
              </div>
              <div class="col-md-4 text-right" style={{ marginLeft: "-4%" }}>
                <div className="row">
                  <div className="col-md-6">
                    <span style={{ color: "orange" }}>Electrical</span>
                  </div>
                  <div className="col-md-6">
                    <Divider
                      style={{
                        height: "6px",
                        marginTop: "6px",
                        background: "#b32246",
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <span style={{ color: "orange" }}>Mechanical</span>
                  </div>
                  <div className="col-md-6">
                    <Divider
                      style={{
                        height: "6px",
                        background: "#225eb3",
                        marginTop: "6px",
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <span style={{ color: "orange" }}>
                      Electrical, Mechanical
                    </span>
                  </div>
                  <div className="col-md-6">
                    <Divider
                      style={{
                        height: "6px",
                        background: "#FFFF00",
                        marginTop: "6px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Demo
        categoryId={selectedCategory ? selectedCategory.id : null}
        setSelectedCategory={setSelectedCategory}
        setHistory={setVisitHistory}
        history={visitHistory}
        historyCount={historyCount}
        nextIndex={nextIndex}
        setHistoryCount={setHistoryCount}
        setNextIndex={setNextIndex}
        refresh={refresh}
        updateNavHistory={updateNavHistory}
        setNavHistory={setNavHistory}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryList: state.manageLevel.categoryList,
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCategoryList: (list) => dispatch(setCategoryList(list)),
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
