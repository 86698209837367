import { combineReducers } from "redux";
import {
  SET_CATEGORY,
  SET_CATEGORY_LIST,
  SET_CURRENT_USER,
  LOGOUT_USER,
  SET_MANAGE_LEVELS_UPLOADING,
} from "../actions";

const categoryReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.list,
      };
    case SET_MANAGE_LEVELS_UPLOADING:
      return {
        ...state,
        uploading: action.uploading,
      };
    default:
      return state;
  }
};

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
      };
    case LOGOUT_USER:
      delete state.currentUser;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default combineReducers({
  manageLevel: categoryReducer,
  user: userReducer,
});
