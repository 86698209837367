import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "../reducer";
import { saveState, loadState } from "../localStorage";
const persistedState = loadState();

const initStore = (initialState = {}) => {
  if (process.browser && window.__store) {
    return window.__store;
  }

  const middleware = [];

  const store = createStore(
    reducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  if (process.browser) {
    window.__store = store;
  }

  store.subscribe(() => {
    saveState(store.getState());
  });

  return store;
};

export default initStore;
