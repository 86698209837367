import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import paths from "../../paths";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import Tabs from "../tabs";
import Toolbar from "@material-ui/core/Toolbar";
import { signOutUser } from "../../actions";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const NewAdminProfile = (props) => {
  const [user, setUser] = useState({
    type: "Admin",
  });

  const [errors, setErrors] = useState(null);

  useEffect(() => {
    let url = `${process.env.REACT_APP_DORMAN_API_URL}/home`;

    if (process.browser) {
      localStorage.getItem("client") &&
        localStorage.getItem("access-token") &&
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access-token": localStorage.getItem("access-token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
          .then((response) => {
            if (response.status == 401) {
              props.signOutUser();
              props.history.push(paths.signIn);
            }
          })
          .catch((error) => {
            alert(error);
            // toast.error(error);
          });
    }
  }, []);

  const handleInputChange = (e) => {
    setErrors(null);

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push(paths.signIn);
    }
  }, []);

  const createUser = () => {
    fetch(`${process.env.REACT_APP_DORMAN_API_URL}/admin/users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-token": localStorage.getItem("access-token"),
        client: localStorage.getItem("client"),
        uid: localStorage.getItem("uid"),
      },
      body: JSON.stringify({ user: user }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((responseJson) => {
            toast.success("Admin account created successfully");
            props.history.push("/home");
          });
        } else {
          return response.json().then((responseJson) => {
            throw new Error(responseJson.error);
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};

    if (!user.email || user.email.trim() == "")
      errors.email = "This field is required.";
    if (!user.password || user.password.trim() == "")
      errors.password = "This field is required.";
    if (!user.password_confirmation || user.password_confirmation.trim() == "")
      errors.password_confirmation = "This field is required.";
    if (user.password !== user.password_confirmation) {
      errors.password = "Password should match with password confirmation.";
      errors.password_confirmation =
        "Password should match with password confirmation.";
    }

    if (Object.keys(errors) == 0) {
      errors = null;
    }

    setErrors(errors);
    if (!errors) {
      createUser();
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <Tabs />
      </ElevationScroll>
      <Container className="mt-3">
        <div class="container">
          <div class="row flex-lg-nowrap">
            <div class="col-12 col-lg-auto mb-3" style={{ width: "200px" }}>
              <div class="card p-3">
                <h5>New Admin Profile</h5>
              </div>
            </div>

            <div class="col">
              <div class="row">
                <div class="col mb-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="e-profile">
                        <div class="row">
                          <div class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                            <div class="text-center text-sm-left mb-2 mb-sm-0">
                              <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">
                                {user.name}
                              </h4>
                            </div>
                            <div class="text-center text-sm-right">
                              <span class="badge badge-secondary">
                                {props.currentUser && props.currentUser.role}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="tab-content pt-3">
                          <div class="tab-pane active">
                            <div class="row">
                              <div class="col">
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label>First Name</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="first_name"
                                        placeholder="First Name"
                                        required="required"
                                        onChange={handleInputChange}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors && errors.first_name}
                                      </span>
                                    </div>
                                    <div class="form-group">
                                      <label>Last Name</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="last_name"
                                        placeholder="Last Name"
                                        required="required"
                                        onChange={handleInputChange}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors && errors.last_name}
                                      </span>
                                    </div>
                                    <div class="form-group">
                                      <label>Email</label>
                                      <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        placeholder="Email Address"
                                        required="required"
                                        onChange={handleInputChange}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors && errors.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 col-sm-6 mb-3">
                                <div class="mb-2">
                                  <b>Password</b>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label>New Password</label>
                                      <input
                                        type="password"
                                        class="form-control"
                                        name="password"
                                        placeholder="Password"
                                        required="required"
                                        onChange={handleInputChange}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors && errors.password}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label>
                                        Confirm{" "}
                                        <span class="d-none d-xl-inline">
                                          Password
                                        </span>
                                      </label>
                                      <input
                                        type="password"
                                        class="form-control"
                                        name="password_confirmation"
                                        placeholder="Confirm Password"
                                        required="required"
                                        onChange={handleInputChange}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errors && errors.password_confirmation}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col d-flex justify-content-end">
                                <button
                                  class="btn btn-dark"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-3 mb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewAdminProfile)
);
